<template>
  <div>
    <div class="footer">
      <div class="item hidden-xs-only">
        <dl class="nav_title">
          <dt @click="switchPage('about')">{{ $t('lang.about') }}</dt>
          <dd @click="switchPage('status')">{{ $t('lang.status') }}</dd>
          <dd @click="switchPage('introduce')">{{ $t('lang.introduce') }}</dd>
          <dd @click="switchPage('recruit')">{{ $t('lang.recruit') }}</dd>
          <dd @click="switchPage('contact')">{{ $t('lang.contact') }}</dd>
        </dl>
        <dl class="support">
          <dt>{{ $t('lang.support') }}</dt>
          <dd @click="switchPage('privacy')">
            {{ $t('lang.serviceAgreement') }}
          </dd>
          <dd @click="switchPage('privacy')">
            {{ $t('lang.privacyStatement') }}
          </dd>
        </dl>
        <dl class="contact">
          <dt>{{ $t('lang.contactInfo') }}</dt>
          <dd>
            {{
              language == 'cn'
                ? companyInfo.cn_company
                : language == 'jp'
                ? companyInfo.jp_company
                : language == 'en'
                ? companyInfo.en_company
                : ''
            }}
          </dd>
          <dd>
            {{
              language == 'cn'
                ? companyInfo.cn_address
                : language == 'jp'
                ? companyInfo.jp_address
                : language == 'en'
                ? companyInfo.en_address
                : ''
            }}
          </dd>
        </dl>
      </div>
      <div class="item hidden-sm-and-up">
        <span @click="switchPage('contact')">{{ $t('lang.contact') }}</span>
        <span @click="switchPage('privacy')">{{
          $t('lang.serviceAgreement')
        }}</span>
        <span @click="switchPage('privacy')">{{
          $t('lang.privacyStatement')
        }}</span>
      </div>
      <div class="copyright">
        <p>copyright © 2021 All rights reserved.</p>
        <!-- <p>备案号</p> -->
      </div>
    </div>
  </div>
</template>

<script>
import { sourceCompany } from '../api/index'
export default {
  data() {
    return {
      language: window.sessionStorage.getItem('language')
        ? window.sessionStorage.getItem('language')
        : 'jp',
      companyInfo: {},
    }
  },
  created() {
    this.getCompanyInfo()
  },
  methods: {
    switchPage(page) {
      this.$router.push({ name: page })
    },
    getCompanyInfo() {
      sourceCompany({ document: 1 })
        .then((res) => {
          if (res.code == '20000') {
            this.companyInfo = res.data.info
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
@media screen and (min-width: 768px) {
  .footer {
    width: 100%;
    height: 371px;
    background-color: rgba(209, 201, 199, 0.25);
    .item {
      display: flex;
      justify-content: center;
      .nav_title {
        margin: 78px 0 0 0;
        dt {
          cursor: pointer;
          height: 20px;
          color: #000000;
          font-size: 14px;
          letter-spacing: 0;
          line-height: 20px;
          margin-bottom: 21px;
        }
        dd {
          cursor: pointer;
          height: 34px;
          color: #000000;
          font-size: 14px;
          letter-spacing: 0;
          line-height: 34px;
          margin-bottom: 21px;
        }
      }
      .support {
        margin: 78px 0 0 170px;
        dt {
          height: 20px;
          color: #000000;
          font-size: 14px;
          font-weight: 600;
          letter-spacing: 0;
          line-height: 20px;
          margin-bottom: 21px;
        }
        dd {
          cursor: pointer;
          height: 34px;
          color: #000000;
          font-size: 14px;
          letter-spacing: 0;
          line-height: 34px;
          margin-bottom: 21px;
        }
      }
      .contact {
        margin: 78px 0 0 158px;
        dt {
          height: 20px;
          color: #292929;
          font-size: 14px;
          font-weight: 600;
          letter-spacing: 0;
          line-height: 20px;
          margin-bottom: 21px;
        }
        dd {
          height: 34px;
          color: #292929;
          font-size: 14px;
          letter-spacing: 0;
          line-height: 34px;
          margin-bottom: 21px;
        }
      }
    }
    .copyright {
      width: 100%;
      text-align: center;
      p {
        color: #898ea2;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 16px;
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .footer {
    padding-top: 38px;
    width: 100%;
    height: 104px;
    background-color: rgba(209, 201, 199, 0.25);
    .item {
      display: flex;
      justify-content: space-around;
      &::after,
      &::before {
        content: '';
        display: block;
      }
      span {
        cursor: pointer;
        color: #000000;
        font-size: 8px;
        line-height: 11px;
      }
    }
    .copyright {
      width: 100%;
      text-align: center;
      p {
        margin: 20px 0 0 0;
        color: #898ea2;
        font-size: 10px;
        line-height: 16px;
      }
    }
  }
}
</style>
