<template>
  <div>
    <div ref="container" class="container">
      <el-row>
        <el-col :xs="20" :sm="8" :md="9" :lg="9" :xl="10">
          <el-col class="hidden-xs-only" :xs="0" :sm="4" :md="4" :lg="4" :xl="5"
            >&nbsp;</el-col
          >
          <el-col :xs="24" :sm="20" :md="20" :lg="20" :xl="19" class="nav_item">
            <el-col
              :xs="4"
              class="btn-con hidden-sm-and-up"
              @click.native="open"
            >
              <i :class="['draw', { closeDown: openflag }]"></i>
              <i :class="['draw', { closeTop: openflag }]"></i>
            </el-col>
            <el-col class="company_info" :xs="16" @click.native="homePage()">
              <img
                class="company_logo"
                src="../assets/img/logo.png"
                alt="图片刷新失败"
              />
              <div class="company_box">
                <span class="company_name">{{ $t('lang.companyName') }}</span>
                <span class="copnay_name_en hidden-xs-only">{{
                  $t('lang.companyName_en')
                }}</span>
              </div>
            </el-col>
          </el-col>
        </el-col>
        <el-col class="hidden-xs-only" :sm="15" :md="13" :lg="13" :xl="12">
          <ul class="nav_title">
            <li
              :class="activeIndex == '1' ? 'active' : ''"
              @click="navClick('about', '1')"
            >
              {{ $t('lang.about') }}
            </li>
            <li class="line">/</li>
            <li
              :class="activeIndex == '2' ? 'active' : ''"
              @click="navClick('status', '2')"
            >
              {{ $t('lang.status') }}
            </li>
            <li class="line">/</li>
            <li
              :class="activeIndex == '3' ? 'active' : ''"
              @click="navClick('introduce', '3')"
            >
              {{ $t('lang.introduce') }}
            </li>
            <li class="line">/</li>
            <li
              :class="activeIndex == '4' ? 'active' : ''"
              @click="navClick('recruit', '4')"
            >
              {{ $t('lang.recruit') }}
            </li>
            <li class="line">/</li>
            <li
              :class="activeIndex == '5' ? 'active' : ''"
              @click="navClick('contact', '5')"
            >
              {{ $t('lang.contact') }}
            </li>
          </ul>
        </el-col>
        <el-col :xs="4" :sm="1" :md="2" :lg="2" :xl="2" class="language">
          <el-dropdown
            trigger="click"
            @command="handleCommand"
            placement="bottom"
          >
            <span class="international iconfont icon-huaban-"></span>
            <el-dropdown-menu slot="dropdown" class="dropDown">
              <el-dropdown-item command="cn"
                ><el-radio v-model="langRadio" label="cn"
                  >Chinese</el-radio
                ></el-dropdown-item
              >
              <el-dropdown-item command="jp"
                ><el-radio v-model="langRadio" label="jp"
                  >Japanese</el-radio
                ></el-dropdown-item
              >
              <el-dropdown-item command="en"
                ><el-radio v-model="langRadio" label="en"
                  >English</el-radio
                ></el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </el-col>
      </el-row>
      <el-row class="navBar hidden-sm-and-up">
        <el-col class="nav_title" :xs="24">
          <ul>
            <li
              :class="activeIndex == '1' ? 'active' : ''"
              @click="navClick('about', 1)"
            >
              {{ $t('lang.about') }}
            </li>

            <li
              :class="activeIndex == '2' ? 'active' : ''"
              @click="navClick('status', 2)"
            >
              {{ $t('lang.status') }}
            </li>
            <li
              :class="activeIndex == '3' ? 'active' : ''"
              @click="navClick('introduce', '3')"
            >
              {{ $t('lang.introduce') }}
            </li>
            <li
              :class="activeIndex == '4' ? 'active' : ''"
              @click="navClick('recruit', 4)"
            >
              {{ $t('lang.recruit') }}
            </li>

            <li
              :class="activeIndex == '5' ? 'active' : ''"
              @click="navClick('contact', 5)"
            >
              {{ $t('lang.contact') }}
            </li>
          </ul>
        </el-col>
        <el-col class="company">
          <el-row>
            <el-col class="c_name">
              {{
                language == 'cn'
                  ? companyInfo.cn_company
                  : language == 'jp'
                  ? companyInfo.jp_company
                  : language == 'en'
                  ? companyInfo.en_company
                  : ''
              }}
            </el-col>
          </el-row>
          <el-row class="c_other">
            <el-col :span="8">{{ $t('lang.address_name') }}：</el-col>
            <el-col :span="16">{{
              language == 'cn'
                ? companyInfo.cn_address
                : language == 'jp'
                ? companyInfo.jp_address
                : language == 'en'
                ? companyInfo.en_address
                : ''
            }}</el-col>
          </el-row>
          <el-row class="c_other">
            <el-col :span="8">{{ $t('lang.branch_company') }}：</el-col>
            <el-col :span="16">{{
              language == 'cn'
                ? companyInfo.cn_address_two
                : language == 'jp'
                ? companyInfo.jp_address_two
                : language == 'en'
                ? companyInfo.en_address_two
                : ''
            }}</el-col>
          </el-row>
          <el-row class="c_other">
            <el-col :span="8">{{ $t('lang.phone') }}：</el-col>
            <el-col :span="16">{{ companyInfo.mobile }}</el-col>
          </el-row>
          <el-row class="c_other">
            <el-col :span="8">{{ $t('lang.email') }}:</el-col>
            <el-col :span="16">{{ companyInfo.email }}</el-col>
          </el-row>
        </el-col>
      </el-row>
    </div>
    <div class="container_real"></div>
  </div>
</template>

<script>
import { sourceCompany } from '../api/index'
export default {
  inject: ['reload'],
  data() {
    return {
      activeIndex: '0',
      openflag: false,
      navBarHeight: '',
      langRadio: 'jp',
      language: window.sessionStorage.getItem('language')
        ? window.sessionStorage.getItem('language')
        : 'jp',
      companyInfo: {}
    }
  },
  computed: {},
  created() {
    this.getCompanyInfo()
  },
  mounted() {
    this.activeIndex = window.sessionStorage.getItem('navIndex')
    this.langRadio = window.sessionStorage.getItem('language')
      ? window.sessionStorage.getItem('language')
      : 'jp'
    this.navBarHeight = window.screen.height
    // document.addEventListener('scroll', this.onScroll)
    // 设置全局字体
    // switch (this.language) {
    //   case 'cn':
    //     document.body.style.fontFamily =
    //       '”Microsoft YaHei”,Arial,Helvetica,sans-serif,”宋体”'
    //     break
    //   case 'jp':
    //     document.body.style.fontFamily =
    //       "sans-serif"
    //     break
    //   case 'en':
    //     document.body.style.fontFamily = "'helvetica'"
    //     break
    // }
  },
  beforeDestroy() {
    // document.removeEventListener('scroll', this.onScroll)
  },
  watch: {
    $route: function(to, from) {
      //   console.log(to, from)
      this.activeIndex = to.meta.index
    }
  },
  methods: {
    //弹出框禁止滑动
    noScroll() {
      var mo = function(e) {
        e.preventDefault()
      }
      document.body.style.overflow = 'hidden'
      document.addEventListener('touchmove', mo, false) // 禁止页面滑动
    },
    //弹出框可以滑动
    canScroll() {
      var mo = function(e) {
        e.preventDefault()
      }
      document.body.style.overflow = '' // 出现滚动条
      document.removeEventListener('touchmove', mo, false)
    },
    homePage() {
      var navBar = document.querySelector('.navBar')
      navBar.style.height = 0
      navBar.style.opacity = '0'
      navBar.style.visibility = 'hidden'
      this.openflag = false
      this.$router.push({ name: 'home' })
    },
    navClick(path, index) {
      // console.log(path, index)
      this.activeIndex = index
      window.sessionStorage.setItem('navIndex', index)
      this.$router.push({ name: path })
      this.canScroll()
      var navBar = document.querySelector('.navBar')
      navBar.style.height = 0
      navBar.style.opacity = '0'
      navBar.style.visibility = 'hidden'
      this.openflag = false
    },
    handleCommand(value) {
      console.log(value)
      window.sessionStorage.setItem('language', value)
      this.langRadio = value
      this.$i18n.locale = value
      // this.reload()
      location.reload()
    },
    open() {
      this.openflag = !this.openflag
      var navBar = document.querySelector('.navBar')
      if (this.openflag) {
        //禁止主页面滑动
        this.noScroll()
        navBar.style.opacity = '1'
        navBar.style.height = this.navBarHeight + 'px'
        navBar.style.visibility = 'visible'
        navBar.style.transition = 'all 0.5s'
        console.log(navBar.style.height)
      } else {
        //主页面可滑动
        this.canScroll()
        navBar.style.height = 0
        navBar.style.opacity = '0'
        navBar.style.visibility = 'hidden'
      }
    },
    getCompanyInfo() {
      sourceCompany({ document: 1 })
        .then(res => {
          if (res.code == '20000') {
            this.companyInfo = res.data.info
          }
        })
        .catch(err => {
          console.log(err)
        })
    }
    // onScroll() {
    //   const scrollTop =
    //     document.documentElement.scrollTop + document.body.scrollTop
    //   const headerDom = this.$refs.container
    //   if (scrollTop >= 10) {
    //     if (!headerDom.getAttribute('class').includes('showActive')) {
    //       headerDom.className = 'container showActive' // 添加类名 css里设置动画
    //     }
    //   } else {
    //     headerDom.className = 'container'
    //   }
    // }
  }
}
</script>

<style lang="scss" scoped>
@media screen and (min-width: 1920px) {
  .container {
    width: 100%;
    position: fixed;
    z-index: 111;
    top: 0;
    left: 0;
    height: 132px;
    background-color: #fff;
    // background-color: transparent;
    // transition: all 1s linear 0.2s;
    // box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.3);
    .company_info {
      height: 132px;
      display: flex;
      align-items: center;
      .company_logo {
        cursor: pointer;
        width: 60px;
        height: 60px;
      }
      .company_box {
        display: flex;
        flex-direction: column;
        margin-left: 20px;
        .company_name {
          cursor: pointer;
          font-size: 22px;
          letter-spacing: 3px;
          color: $fontColor;
          // margin-left: 20px;
        }
        .copnay_name_en {
          cursor: pointer;
          font-size: 22px;
          color: $fontColor;
          // margin-left: 20px;
        }
      }
    }

    .nav_title {
      height: 132px;
      display: flex;
      align-items: center;
      li {
        line-height: 48px;
        font-size: 20px;
        color: $fontColor;
        font-weight: 600;
        &:nth-of-type(1),
        &:nth-of-type(3),
        &:nth-of-type(5),
        &:nth-of-type(7),
        &:nth-of-type(9) {
          // width: 130px;
          // padding: 0 20px;
          cursor: pointer;
        }
      }
      .line {
        margin: 0 35px;
      }
      .active {
        border-bottom: 2px solid #bc3810;
        // border-radius: 16px;
      }
    }
    .language {
      height: 132px;
      line-height: 132px;
      .international {
        cursor: pointer;
        font-size: 22px;
        color: $fontColor;
        line-height: 22px;
      }
    }
  }
  .showActive {
    background-color: #ffffff;
  }
  .container_real {
    height: 132px;
    width: 100%;
  }
  .dropDown {
    top: 78px !important;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1919px) {
  .container {
    width: 100%;
    position: fixed;
    z-index: 111;
    top: 0;
    left: 0;
    height: 132px;
    background-color: #fff;
    // background-color: transparent;
    // transition: all 1s linear 0.2s;
    // box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.3);
    .company_info {
      height: 132px;
      display: flex;
      align-items: center;
      .company_logo {
        cursor: pointer;
        width: 60px;
        height: 60px;
      }
      .company_box {
        display: flex;
        flex-direction: column;
        margin-left: 20px;
        .company_name {
          cursor: pointer;
          font-size: 22px;
          letter-spacing: 3px;
          color: $fontColor;
        }
        .copnay_name_en {
          cursor: pointer;
          font-size: 22px;
          color: $fontColor;
        }
      }
    }

    .nav_title {
      height: 132px;
      display: flex;
      align-items: center;
      li {
        line-height: 48px;
        font-size: 18px;
        color: $fontColor;
        font-weight: 600;
        &:nth-of-type(1),
        &:nth-of-type(3),
        &:nth-of-type(5),
        &:nth-of-type(7),
        &:nth-of-type(9) {
          // width: 130px;
          // padding: 0 12px;
          cursor: pointer;
        }
      }
      .line {
        margin: 0 24px;
      }
      .active {
        border-bottom: 2px solid #bc3810;
        // border-radius: 16px;
      }
    }
    .language {
      height: 132px;
      line-height: 132px;
      .international {
        cursor: pointer;
        font-size: 22px;
        color: $fontColor;
        line-height: 22px;
      }
    }
  }
  .showActive {
    background-color: #ffffff;
  }
  .container_real {
    height: 132px;
    width: 100%;
  }
  .dropDown {
    top: 78px !important;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .container {
    width: 100%;
    position: fixed;
    z-index: 111;
    top: 0;
    left: 0;
    height: 132px;
    background-color: #fff;
    // background-color: transparent;
    // transition: all 1s linear 0.2s;
    // box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.3);
    .company_info {
      height: 132px;
      display: flex;
      align-items: center;
      .company_logo {
        cursor: pointer;
        width: 56px;
        height: 56px;
      }
      .company_box {
        display: flex;
        flex-direction: column;
        margin-left: 20px;
        .company_name {
          cursor: pointer;
          font-size: 20px;
          letter-spacing: 3px;
          color: $fontColor;
        }
        .copnay_name_en {
          cursor: pointer;
          font-size: 22px;
          color: $fontColor;
        }
      }
    }

    .nav_title {
      height: 132px;
      display: flex;
      align-items: center;
      li {
        line-height: 48px;
        font-size: 18px;
        color: $fontColor;
        font-weight: 600;
        &:nth-of-type(1),
        &:nth-of-type(3),
        &:nth-of-type(5),
        &:nth-of-type(7),
        &:nth-of-type(9) {
          // width: 130px;
          // padding: 0 12px;
          cursor: pointer;
        }
      }
      .line {
        margin: 0 20px;
      }
      .active {
        border-bottom: 2px solid #bc3810;
        // border-radius: 16px;
      }
    }
    .language {
      height: 132px;
      line-height: 132px;
      .international {
        cursor: pointer;
        font-size: 22px;
        color: $fontColor;
        line-height: 22px;
      }
    }
  }
  .showActive {
    background-color: #ffffff;
  }
  .container_real {
    height: 132px;
    width: 100%;
  }
  .dropDown {
    top: 78px !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .container {
    width: 100%;
    position: fixed;
    z-index: 111;
    top: 0;
    left: 0;
    height: 132px;
    background-color: #fff;
    // background-color: transparent;
    // transition: all 1s linear 0.2s;
    // box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.3);
    .company_info {
      height: 132px;
      display: flex;
      align-items: center;
      .company_logo {
        cursor: pointer;
        width: 52px;
        height: 52px;
      }
      .company_box {
        display: flex;
        flex-direction: column;
        margin-left: 20px;
        .company_name {
          cursor: pointer;
          font-size: 18px;
          letter-spacing: 3px;
          color: $fontColor;
        }
        .copnay_name_en {
          cursor: pointer;
          font-size: 22px;
          color: $fontColor;
        }
      }
    }

    .nav_title {
      height: 132px;
      display: flex;
      align-items: center;
      li {
        line-height: 48px;
        font-size: 16px;
        color: $fontColor;
        font-weight: 600;
        &:nth-of-type(1),
        &:nth-of-type(3),
        &:nth-of-type(5),
        &:nth-of-type(7),
        &:nth-of-type(9) {
          // width: 130px;
          // padding: 0 12px;
          cursor: pointer;
        }
      }
      .line {
        margin: 0 20px;
      }
      .active {
        border-bottom: 2px solid #bc3810;
        // border-radius: 16px;
      }
    }
    .language {
      height: 132px;
      line-height: 132px;
      .international {
        cursor: pointer;
        font-size: 22px;
        color: $fontColor;
        line-height: 22px;
      }
    }
  }
  .showActive {
    background-color: #ffffff;
  }
  .container_real {
    height: 132px;
    width: 100%;
  }
  .dropDown {
    top: 78px !important;
  }
}

@media screen and (max-width: 767px) {
  .container {
    position: fixed;
    z-index: 111;
    height: 50px;
    width: 100%;
    background-color: #ffffff;
    // box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
    .btn-con {
      position: relative;
      z-index: 112;
      // top: 0;
      cursor: pointer;
      display: flex;
      justify-content: center;
    }
    .btn-con i.draw {
      display: block;
      width: 14px;
      height: 2px;
      background: #fff;
      transition: 0.3s;
    }
    .btn-con i.draw:first-of-type,
    .btn-con i.draw:last-of-type {
      display: block;
      width: 14px;
      height: 2px;
      background: #464855;
      position: absolute;
      display: block;
      outline: none;
      top: 20px;
      transition: 0.3s;
    }
    .btn-con i.draw:last-of-type {
      top: 30px;
    }
    .btn-con i.draw.closeDown {
      top: 25px;
      transform: rotate(-45deg);
    }
    .btn-con i.draw.closeTop {
      top: 25px;
      transform: rotate(45deg);
    }
    .nav_item {
      display: flex;
      height: 50px;
      .company_info {
        width: 100%;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        .company_logo {
          cursor: pointer;
          width: 23px;
          height: 23px;
          vertical-align: middle;
        }
        .company_box {
          .company_name {
            cursor: pointer;
            font-size: 16px;
            letter-spacing: 3px;
            color: $fontColor;
            margin-left: 10px;
            font-weight: 500;
            letter-spacing: 2px;
          }
          .copnay_name_en {
            cursor: pointer;
            font-size: 16px;
            color: $fontColor;
            margin-left: 10px;
            font-weight: 500;
            letter-spacing: 2px;
          }
        }
      }
    }
    .language {
      height: 50px;
      display: flex;
      // justify-content: center;
      align-items: center;
      .international {
        cursor: pointer;
        outline: none;
      }
    }
    .navBar {
      visibility: hidden;
      position: absolute;
      z-index: 9999;
      top: 50px;
      height: 100%;
      width: 100%;
      background-color: #ffffff;
      .nav_title {
        padding: 0 34px;
        li {
          cursor: pointer;
          height: 43px;
          line-height: 43px;
          font-size: 14px;
          color: #464855;
          border-bottom: 1px solid #bfbfbf;
        }
      }

      .company {
        padding: 0 34px;
        .c_name {
          margin: 34px 0 20px 0;
          height: 22px;
          color: #464855;
          font-size: 16px;
          letter-spacing: 0.21px;
          line-height: 22px;
        }
        .c_other {
          display: flex;
          margin-bottom: 12px;
          line-height: 17px;
          .el-col {
            color: #464855;
            font-size: 12px;
            letter-spacing: 0.16px;
          }
        }
      }
    }
  }
  // .showActive {
  //   background-color: #ffffff;
  // }
  .container_real {
    height: 50px;
    width: 100%;
  }
  .dropDown {
    top: 38px !important;
  }
}
</style>
